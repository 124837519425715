<template>
  <div class="echarts">
    <div class="top-div">
      <div class="top-divs">
        <div class="logo">
          <img src="./../assets/img/logo.png" />
        </div>
        <div class="top-name">{{ this.$t(`right99`) }}</div>
      </div>
      <div class="weather">
        <el-tabs v-model="activeName" @tab-click="handleClick" style="font-size: 12px;" class="qiehuan-title">
          <el-tab-pane :label="consoleTitle" name="1"></el-tab-pane>
          <el-tab-pane :label="historydata" name="2"></el-tab-pane>
          <el-tab-pane :label="thermalTrajectory" name="3"></el-tab-pane>
        </el-tabs>
        <div class="opera-item icon" @click="changeLocale()">
          <img src="./../assets/img/zh_en.png" alt="" />
        </div>
        <div class="opera-item icon" @click="toBig()">
          <img src="./../assets/img/q_p.png" alt="" />
        </div>
        <div class="opera-item icon" @click="outLogin()">
          <img src="./../assets/img/out_login.png" alt="" />
        </div>
      </div>
      <div class="tanP">
        <div class="tanp-img">
          <img src="./../assets/img/tanp_1.png" />
        </div>
        <div class="tanp-name">
          {{ this.$t(`right25`) }}
          <span>
            <countTo :startVal="$store.state.login.oldElectricityTotal" :endVal="$store.state.login.electricityTotal" :duration="1300" :decimals="0"> </countTo>
          </span>kwh,
          {{ this.$t(`right100`) }}
          <span>
            <countTo :startVal="$store.state.login.oldReduceConsume" :endVal="$store.state.login.reduceConsume" :duration="1300" :decimals="2"> </countTo>
          </span>kg
        </div>
      </div>
    </div>
    <div class="mainBox">
      <router-view :typename="typename" :screenfullq="screenfullq" />
    </div>
  </div>
</template>
<script>
import foowwLocalStorage from "../utils/foow.js";
import { getReduceConsume } from "../request/conlog.js";
import CountTo from "vue-count-to" 
import screenfull from "screenfull"; //全屏
export default {
  name: "index",
  components: {
    CountTo
  },
  inject:['reload'],
  data() {
    return {
      activeName: '1',
      consoleTitle: undefined,
      historydata: undefined,
      thermalTrajectory: undefined,
      reduceConsume: 0,
      screenfullq: 0,
      typename: 0,
      numberR: "1",
      place: null,
      map: null,
      kongzhitai: true,
      history: false,
      guiji: false,
    };
  },
  watch: {
    $route(to, from) {
      if (to.path === "/history") {
        this.typename = 2;
      } else if (to.path === "/layout") {
        this.typename = 1;
      }
    },
  },
  mounted() {
    if(localStorage.getItem('activeName')){
      this.activeName = localStorage.getItem('activeName')
    }
    this.consoleTitle = this.$t("index.console");
    this.historydata = this.$t("index.historydata");
    this.thermalTrajectory = this.$t("index.thermalTrajectory");
    getReduceConsume({}).then((res) => {
      if (res.errcode === 200) {
        let carbonEmissions = parseFloat(res.data.data.carbonEmissions);
        let electricityTotal = parseFloat(res.data.data.electricityTotal);
        this.$store.commit("login/setReduceConsume", carbonEmissions);
        this.$store.commit("login/setElectricityTotal", electricityTotal);
      }
    });
  },
  methods: {
    handleClick(){
      this.toShow(Number(this.activeName))
    },
    outLogin() {
      localStorage.removeItem("base-token");
      this.$router.push("/login");
    },
    toShow(index) {
      if (index === 1) {
        this.$router.push("/layout");
      } else if (index === 2) {
        this.$router.push("/history");
      } else if (index === 3) {
        this.$router.push("/hot");
      }
      localStorage.setItem('activeName', this.activeName)
    },
    //全屏
    toBig() {
      screenfull.toggle();
    },
    // js方法
    changeLocale() {
      this.$confirm(this.$t("layer.toggle"), this.$t("layer.tips"), {
        confirmButtonText: this.$t("button.ok"),
        cancelButtonText: this.$t("button.cancel"),
        type: "warning",
      })
        .then(() => {
          let locale = this.$i18n.locale;
          locale === "zh"
            ? (this.$i18n.locale = "en")
            : (this.$i18n.locale = "zh");
          locale === "zh"
            ? (this.startDate = "Start date")
            : (this.serieData = "开始日期");
          locale === "zh"
            ? (this.endDate = "End date")
            : (this.endDate = "结束日期");
          locale === "zh" ? (this.to = "to") : (this.to = "to");
          this.reload();
        })
        .catch(() => {
        });
    },
  },
};
</script>
<style scoped>
* {
  margin: 0;
  padding: 0;
}
.top-div {
  width: 100%;
  height: 0.25rem;
  color: #fff;
  position: absolute;
  top: 0;
  /*以上设置是重点必须的*/
  text-align: center;
  line-height: 0.25rem;
  background-color: #000B3F;
}
.top-divs {
  display: flex;
  justify-content: center;
  height: 0.25rem;
  line-height: 0.22rem;
}
.top-name {
  font-size: 0.1rem;
  font-weight: bold;
}
.logo {
  width: 0.4rem;
  height: 0.14rem;
  margin-right: 0.02rem;
  margin-top: -0.32rem;
}
.logo img {
  width: 100%;
  height: 100%;
}
.mainBox {
  width: calc(100%);
  height: calc(100vh - 0.2rem);
  margin-top: 0.2rem;
  bottom: 0;
  background-color: #000B3F;
}
.weather {
  color: #c4d1e8;
  font-size: 0.06rem;
  position: absolute;
  right: 0rem;
  top: 0.03rem;
  line-height: 0.15rem;
  display: flex;
}
.weather img {
  width: 0.37rem;
  display: inline-block;
  vertical-align: middle;
}
.weather span {
  color: rgba(255, 255, 255, 0.7);
  font-size: 0.18rem;
  padding-right: 0.1rem;
}
.tanP {
  color: #66beff;
  font-size: 0.15rem;
  position: absolute;
  left: 0rem;
  top: 0rem;
  display: flex;
}
.tanp-img {
  width: 0.15rem;
  height: 0.15rem;
  margin-right: 0.02rem;
  margin-left: 0.04rem;
}
.tanp-img img {
  width: 100%;
  height: 100%;
}
.tanp-name {
  font-size: 0.06rem;
  color: #d4e1f6;
  margin-top: -0.025rem;
}
.tanp-name span {
  color: #2ab576;
  font-size: 0.08rem;
}
.echarts {
  width: 100%;
}
.opera {
  display: flex;
  font-size: 0.46rem;
  text-align: right;
  position: absolute;
  right: 4rem;
  bottom: 1rem;
  color: #66beff;
  justify-content: space-around;
}
.opera-item {
  margin: 0 0.05rem;
  height: 0.15rem;
  line-height: 0.15rem;
}
.opera-item.icon {
  width: 0.08rem;
  height: 0.08rem;
  /* margin-top: 0.02rem; */
}
.opera-item.icon img {
  width: 100%;
  height: 100%;
}
.el-range-editor .el-range-input {
  background-color: #021418;
  color: #026476;
}
.top {
  height: 100px;
  background: #bbe8f2;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}
body{
	-moz-user-select:none;
}
</style>
<style>
.el-tabs__nav-wrap::after {
  background-color: inherit;
}
.el-tabs__item {
  color: #c4d1e8;
}
.el-tabs__item {
  height: 24px;
  line-height: 24px;
}
.qiehuan-title .el-tabs__item.is-active {
  color: #31D96C;
}
.qiehuan-title .el-tabs__active-bar {
  background-color: #31D96C;
}
.qiehuan-title .el-tabs__item:hover {
  color: #31D96C;
  cursor: pointer;
}
</style>
